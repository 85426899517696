import { render, staticRenderFns } from "./component.vue?vue&type=template&id=b34245bc&lang=html&"
import script from "./component.vue?vue&type=script&lang=js&"
export * from "./component.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../.config/yarn/global/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports